import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2024/5C/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2024/5C/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2024/5C/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2024/5C/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2024/5C/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2024/5C/6.jpg';
// import p7 from 'assests/Photos/ClassAssembly/2024/5C/7.jpg';






// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly5C2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/5C/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/5C/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/5C/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/5C/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/class-assembly/5C/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/class-assembly/5C/6.webp`;
  const p7 = `${config.base_image_url}/home/events-activities/class-assembly/5C/7.webp`;




  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1,
      cols: 1,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
          src: p7,
          source: p7,
          rows: 1.5,
          cols: 2,
        },
     

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      PLANET MATTERS: THE JOURNEY FROM PLASTIC TO PAPER
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 5C Date: 12 July 2024

        </Typography>
        {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Imagination is the beginning of creation. You imagine what you desire, you will what you imagine and at last,
   you create what you will"- George Bernard Shaw

<br/>
        </Typography> */}

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 5C presented a special assembly on the theme “Planet Matters: The Journey from Plastic to Paper.” The assembly began with a thought for the day, followed by an introduction to the topic, highlighting the impact of plastic on the environment.                                                  
        <br></br>
        The assembly featured three engaging scenes illustrating the harmful effects of plastic and the benefits of using paper bags. It included a dance performance and emphasised the significance of Plastic Bag Free Day and Paper Bag Day. During their performance, the students conveyed that while plastic is convenient, it poses serious environmental challenges, including harm to wildlife, ocean pollution and negative health impacts. They contrasted this with paper, which is biodegradable, recyclable and a more sustainable option.
        <br></br>
        The assembly concluded with the students taking a pledge to reduce plastic usage and promote eco-friendly practices. This informative presentation left a lasting impression, inspiring the audience to make more sustainable choices in their daily lives.
        <br></br>
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Don’t let plastic bottles be our history fossils.”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly5C2024;